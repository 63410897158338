<!-- eslint-disable -->
<template>
<v-container
    id="dashboard"
    fluid
    tag="section"
  >
   <v-row class="text-left">
      <v-col cols="12" md="6"><span class="headline">eOffice V3</span></v-col>
      <v-col cols="12" md="6" class="text-right" style="vertical-align:middle;padding:10px">
        <a href="/#/login">
            <v-btn color="blue lighten-1" >Sign In</v-btn>
          </a>
      </v-col>
    </v-row>
    <p style="border-top:4px solid #4DA521;"></p>
    <br>
    <v-row
        align="center"
        justify="center"
    >

        <v-col
            cols="12"
            md="12"
            class="text-left"
        >
            <v-alert
              dense
              text
              type="info"
            >
              <p class="black--text subtitle-2">If you want to open the next page you must enter your password, this link will open the letter with the following details:</p>
              <table>
                <tr class="black--text font-weight-bold">
                  <td width="150px">Modul</td>
                  <td width="10px">:</td>
                  <td>{{ moduleType }}</td>
                </tr>
                <tr class="black--text font-weight-bold">
                  <td>No. Surat</td>
                  <td>:</td>
                  <td>{{ moduleData.letter_no }}</td>
                </tr>
              </table>
              <p class="black--text font-weight-regular">For security reasons, please enter your password in the fields below.</p>
            </v-alert>

            <v-card class="mx-auto px-3 py-3">
              <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                <table>
                  <tr class="black--text font-weight-bold white">
                    <td width="150px">NAMA</td>
                    <td width="10px">:</td>
                    <td width="300px">{{ userInfo.name }}</td>
                  </tr>
                  <tr class="black--text font-weight-bold white">
                    <td>PASSWORD</td>
                    <td>:</td>
                    <td class="black--text font-weight-regular">
                      <v-text-field
                              v-model="loginForm.password"
                              label="Password"
                              :type="showPass ? 'text' : 'password'"
                              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                              @click:append="showPass = !showPass"
                              :rules="[rules.required, rules.min]"
                              ref="textpass"
                          ></v-text-field>
                    </td>
                  </tr>
                </table>
                <v-divider></v-divider>
                <v-spacer></v-spacer>
                <div class="text-right" v-if="moduleData.letterGUID !=''">
                  <v-btn
                              :disabled="!valid || loginForm.password.length <= 0"
                              color="success"
                              class="mr-4"
                              @click="handleLogin()"
                          >
                          Buka Surat
                          </v-btn>
                </div>
                <div class="text-right" v-else>
                  <span class="red--text">This link has broken or may the Letter has been deleted!</span>
                </div>
              </v-form>
            </v-card>
            
             <v-alert
              dense
              text
              type="warning"
            >
              <span class="brown--text font-weight-regular body-1">If you are a <u>different</u> user please click on the <b>Home</b> or <b>Sign In</b> from the button links above.</span>
            </v-alert>
        </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>
    
  </v-container>
</template>
<script>
  import secureStorage from '@/utils/secureStorage'
  import { _GLOBAL } from '@/api/server'
  import moment from 'moment'

  import { getAuthLogin } from '@/api/authdirectlogin'
  import { getListUsrPosActiveAll } from '@/api/hruserpositions'
  import { loginByUsername } from '@/api/login'
  import { getALLHROrganization } from '@/api/hrorganization'
  import { getListPositionsActive } from '@/api/hrpositions'
  import { getActiveUser } from '@/api/admin'
  import { getUserPosRoles } from '@/api/positionRoles'

  export default {
    name: 'authlogin',
    data() {
      return {
        rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 8 || 'Min 8 characters',
        },
        valid: false,
        showPass: false,
        vivus: '',
        toast: { show: false, color:'green', text:'', timeout: 2000 },
        isLoading: false,
        errType: 0,
        requestKey: '',
        authData: [],
        moduleType: '-',
        loginForm: {
          username: '',
          password: ''
        },
        userInfo: {
          user_id: 0,
          name: '',
        },
        moduleData: {
          letter_no: '-',
          letter_id: '0',
          letterGUID: '',
          letter_type: 0,
          letterinout: 0,
          userPosID: 0
        },
      }
    },
     mounted () {
      this.requestKey = this.$route.params && this.$route.params.key
      this.authData = this.requestKey.match(/.{36}/g);
      // console.log(this.authData)

      this.getData()
    },
    methods:{
      getData() {
        const vm = this
        if(this.authData == null){
          console.log('auth data null')
          return false
        }
        var key1 = this.authData[0]
        var key2 = this.authData[1]
        vm.isLoading = true

      getAuthLogin(key1, key2).then(response => {
          var arr = response.data
          // console.log(arr)

          if(arr != null){  

            if(arr.data != null){
              if(arr.data.noSurat == null){
                var no_letter = arr.data.id
                var idL = no_letter.toString()
                var strnum = ''
                for(var i = 4;i > idL.length;i--){
                  strnum += '0'
                }
                strnum += strnum+idL
                vm.moduleData.letter_no = '#'+ strnum
              }else{
                vm.moduleData.letter_no = arr.data.noSurat
              }

              vm.moduleData.letter_id = arr.data.id
              vm.moduleData.letterGUID = arr.data.letterGUID
              vm.moduleData.letter_type = arr.data.eolettertype.id
              
            }

            if(arr.auth.letterinout){
              vm.moduleData.letterinout = arr.auth.letterinout.id
            }

            if(arr.auth.moduletypes){
              vm.moduleType = arr.auth.moduletypes.ModuleName
            }

            if(arr.auth.hruserpositions_UserAuth){
              vm.moduleData.userPosID = arr.auth.hruserpositions_UserAuth.id
            }

            if(arr.user != null){
              vm.loginForm = {
                username: arr.user.email,
                password: ''
              }
              vm.userInfo = {
                user_id: arr.user.id,
                name: arr.user.fullname,
              }
            }
          }

          // console.log(vm.moduleData)
          vm.retData = arr
          vm.isLoading = false

          var token = secureStorage.getItem('jwt')
          if (token){
            vm.openDetail()
          }

        }).catch(error => {
          // console.log(error)
          vm.isLoading = false
        })
      },
      openDetail() {
        // console.log(eolettertype)
        // console.log(letterGUID)
        var uri =''
        uri = _GLOBAL.PORTALURL + '#/surat/v/' + this.moduleData.letterGUID
        window.location = uri
        
      },
      async handleLogin(e){
        const vm = this

        if (vm.loginForm.password.length > 0) {
            vm.isLoading = true

            // console.log(vm.loginForm)
            loginByUsername(vm.loginForm.username, vm.loginForm.password ).then(response => {
              console.log('Well done!');
              // console.log('User profile', response.data.user);
              // console.log('User token', response.data.jwt);
              const user = response.data.user
              const token = response.data.jwt

              let is_admin = 0
              if(response.data.user.role.name == "Administrator"){
                  is_admin = 1
              }
              secureStorage.setItem('user',user)
              secureStorage.setItem('jwt',token)
              // vm.$store.state.user = response.data.user

              vm.isLoading = true
              getALLHROrganization().then(response => {
                secureStorage.setItem('orgall',response.data)
                // console.log(rData)
                vm.isLoading = false
              }).catch(error => {
                // console.log(error)
                vm.isLoading = false
              })

              vm.isLoading = true
              getListPositionsActive().then(response => {
                secureStorage.setItem('posall',response.data)
                // console.log(rData)
                vm.isLoading = false
              }).catch(error => {
                // console.log(error)
                vm.isLoading = false
              })

              vm.isLoading = true
              getListUsrPosActiveAll().then(response => {
                secureStorage.setItem('userposall',response.data)
                vm.isLoading = false
              }).catch(error => {
                // console.log(error)
                vm.isLoading = false
              })

              
              vm.isLoading = true
              getActiveUser().then(response => {
                // console.log('getActiveUser ',response.data)
                secureStorage.setItem('userall',response.data)
                vm.isLoading = false
              }).catch(error => {
                // console.log(error)
                vm.isLoading = false
              })

              vm.isLoading = true
              getUserPosRoles(token, user.id).then(response => {
                var userpos = response.data
                console.log('userposroles', userpos)
                secureStorage.setItem('userpos', userpos)

                if (userpos) {
                  userpos.forEach (element => {
                    // console.log('==userPosID', vm.moduleData.userPosID)
                    if (element.hrposition) {
                      var prefix = ''
                      if (element.prefix!='') {
                        prefix = element.prefix + ' '
                      }

                      if(vm.moduleData.userPosID == element.id){
                        secureStorage.setItem('currRole', element)
                        if (secureStorage.getItem('jwt') != null){
                          vm.$emit('loggedIn')
                          vm.openDetail()
                        }
                      }
                    }
                  })
                }
                vm.isLoading = false
                
              }).catch(error => {
                console.log(error)
                vm.isLoading = false
              })
              // console.log('logincrole', secureStorage.getItem('currRole'))

            }).catch(err => {
                console.log(err)
                vm.toast = {
                    show: true, color:'red', text: 'Username or Password was incorrect!', timeout: 2000
                }
                vm.isLoading = false
            })
        }
      }
    }
}
</script>
<style>
</style>
<!-- eslint-enable -->